// @import 'node_modules/bootstrap/scss/bootstrap';
// @import 'node_modules/bootstrap-icons/font/bootstrap-icons.scss';
@import './custombootstrap-icons.scss';
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import "./assets/css/Idupload.scss";
// @import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap");

// Option A: Include all of Bootstrap

// @import 'node_modules/bootstrap/scss/bootstrap';
@import './custombootstrap';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap');
// @import 'node_modules/swiper/swiper-bundle.min.css';
// @import url("node_modules/bootstrap-icons/font/bootstrap-icons.scss");
