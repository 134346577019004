.loading-fallback {
  background-image: linear-gradient(
    to right bottom,
    #1899f5,
    #0090f3,
    #0087f1,
    #007eee,
    #0075eb,
    #086ee1,
    #0d66d7,
    #105fcd,
    #1058ba,
    #1251a8,
    #144a96,
    #174384
  );
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  font-weight: 500;
}
