/*!
 * Bootstrap Icons v1.11.3 (https://icons.getbootstrap.com/)
 * Copyright 2019-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/icons/blob/main/LICENSE)
 */

$bootstrap-icons-font: 'bootstrap-icons' !default;
$bootstrap-icons-font-dir: '~bootstrap-icons/font/fonts' !default;
$bootstrap-icons-font-file: '#{$bootstrap-icons-font-dir}/#{$bootstrap-icons-font}' !default;
$bootstrap-icons-font-hash: '24e3eb84d0bcaf83d77f904c78ac1f47' !default;
$bootstrap-icons-font-src:
  url('#{$bootstrap-icons-font-file}.woff2?#{$bootstrap-icons-font-hash}')
    format('woff2'),
  url('#{$bootstrap-icons-font-file}.woff?#{$bootstrap-icons-font-hash}')
    format('woff') !default;

@font-face {
  font-display: block;
  font-family: $bootstrap-icons-font;
  src: $bootstrap-icons-font-src;
}

.bi::before,
[class^='bi-']::before,
[class*=' bi-']::before {
  display: inline-block;
  font-family: $bootstrap-icons-font !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$bootstrap-icons-map: (
  '123': '\f67f',
  'align-bottom': '\f103',
  'align-center': '\f104',
  'align-end': '\f105',
  'align-middle': '\f106',
  'align-start': '\f107',
  'align-top': '\f108',
  'alt': '\f109',
  'arrow-left': '\f12f',
  'arrow-repeat': '\f130',
  'arrow-right': '\f138',
  'bootstrap-fill': '\f1a6',
  'bootstrap-reboot': '\f1a7',
  'bootstrap': '\f1a8',
  'border-all': '\f1a9',
  'border-bottom': '\f1aa',
  'border-center': '\f1ab',
  'border-inner': '\f1ac',
  'border-left': '\f1ad',
  'border-middle': '\f1ae',
  'border-outer': '\f1af',
  'border-right': '\f1b0',
  'border-style': '\f1b1',
  'border-top': '\f1b2',
  'border-width': '\f1b3',
  'border': '\f1b4',
  'camera-fill': '\f219',
  'check-circle-fill': '\f26a',
  'chevron-down': '\f282',
  'chevron-right': '\f285',
  'cloud-arrow-up': '\f297',
  'whatsapp': '\f618',
  'x-circle': '\f623',
  'x': '\f62a',
  'x-lg': '\f659',
  'car-front-fill': '\f7e0',
);

@each $icon, $codepoint in $bootstrap-icons-map {
  .bi-#{$icon}::before {
    content: $codepoint;
  }
}
